import initScrollReveal from "./scripts/scrollReveal";
import initTiltEffect from "./scripts/tiltAnimation";
import { targetElements, defaultProps } from "./data/scrollRevealConfig";

initScrollReveal(targetElements, defaultProps);
initTiltEffect();

// Definiamo le traduzioni
const translations = {
    en: {
        heroTitle: `Hi, I'm <span class="text-color-main">Maxim Daniel</span><br />software engineer and developer.`,
        knowMore: "Know more",
        aboutTitle: "About me",
        viewResume: "View Resume",
        viewLive: "See Live",
        aboutDescription:
            "Software Engineer and Web Developer with a passion for learning and exploring new technologies. \
            Experienced in Artificial Intelligence and intuitive application design. I leverage logic, creativity \
            and attention to detail to create practical and effective solutions.",// to deliver efficient, high-quality projects.
        aboutGoal:
            `My goal is to learn and create interesting things 

            - Member of Mensa International`,
        sectionJob: "Jobs",
        sectionProjects: "Projects",

        sectionJob1: `I developed web interfaces and web apps integrated with relational databases, working on projects for municipalities, managing registries, orders, and other specific applications. Currently, I collaborate with my manager in managing a portion of the company environment. Rapid adaptation to proprietary technologies and new environments.`,
        sectionJob2: `University internship focused on web development with ASP.NET and the use of the Microsoft Azure platform. Participated in professional projects as part of a team, gaining experience in SQL database management, code handling, and the use of Git for collaborative version control.`,
        sectionProject1: `Web development using HTML, SCSS, JavaScript, and Node.js. Created a responsive website with integrated translation. Manually configured the domain hosted on an external service to point to an AWS instance via DNS and a static IP address.`,
        sectionTtp: "Stress detection through AI",
        sectionProject2: `Development of a deep convolutional neural network (Deep CNN) in Python for stress detection using wearable sensors. Sensory data is converted into images for the application of advanced machine learning models.</br></br>The images show the accuracy test, including generated images, the accuracy achieved during the test, and the results. Additionally, some execution logs during the model's construction demonstrate its progression.`,
        sectionTtp2: "Procedural generation algorithm in video games",
        sectionProject3: `Design of algorithms for the procedural generation of video game content. Implemented in Unity 2D, creating an automated system for generating levels and mazes.</br></br>In the image, you can see a room entirely generated by the algorithm, which also handles the placement of enemies, objects, and the pattern generation of the dungeon procedurally. Simply put, starting from a blank screen, the program can generate a fully-fledged maze.`,


        contactTitle: "Contact",
        contactDescription: "For anything you can write directly to me here:",
        contactCTA: "Contact me",
    },
    it: {
        heroTitle: `Ciao, sono <span class="text-color-main">Maxim Daniel</span><br />ingegnere del software e sviluppatore.`,
        knowMore: "Scopri di pi\u00F9",
        aboutTitle: "Su di me",
        viewResume: "Curriculum",
        viewLive: "Vedi Live",
        aboutDescription:
            "Software Engineer e Web Developer con una passione per l'apprendimento e l'esplorazione di nuove tecnologie. \
            Con esperienza in Intelligenza Artificiale e progettazione di applicazioni intuitive. Unisco logica, \
            creativit\u00E0 e attenzione ai dettagli per creare soluzioni pratiche ed efficaci.",
        aboutGoal:
            `Il mio obbiettivo \u00E8 imparare e creare cose interessanti

            - Membro del Mensa International`,
        sectionJob: "Lavori",
        sectionProjects: "Progetti",

        sectionJob1: ` Ho sviluppato interfacce web e web app integrate con database relazionali,
                                    occupandomi di progetti per enti comunali, gestione di anagrafiche, ordini
                                    e altre applicazioni specifiche. Attualmente collaboro con il mio responsabile
                                    nella gestione di una parte dell'ambiente aziendale.
                                    Adattamento rapido a tecnologie proprietarie e nuovi ambienti.`,
        sectionJob2: ` Tirocinio universitario dove mi sono occupato di sviluppo web con
                                    ASP.NET e utilizzato la piattaforma Microsoft Azure. Ho partecipato a
                                    progetti professionali lavorando in team, acquisendo esperienza
                                    nell'utilizzo di database SQL, nella gestione del codice e
                                    nell'uso di Git per il versionamento collaborativo.`,
        sectionProject1: ` Sviluppo Web in HTML, SCSS, JavaScript & Node.js, realizzato un sito web
                                    responsive con traduzione integrata. Configurato manualmente il dominio
                                    hostato su un servizio esterno per puntare a un'istanza AWS tramite DNS
                                    e IP statico.`,
        sectionTtp: "Individuazione dello stress tramite AI",
        sectionProject2: ` Sviluppo di una rete neurale convoluzionale profonda (Deep CNN)
                                    in Python per la rilevazione dello stress attraverso sensori indossabili.
                                    Dati sensoriali vengono convertiti in immagini per l'applicazione di modelli
                                    avanzati di machine learning.
                                    </br></br>Nelle immagini si pu\u00F2 notare il test dell'accuratezza, dove si vedono le immagini
                                    generate, insieme all'accuratezza raggiunta durante il test e esito. Poi ci sono alcune
                                    righe di esecuzione durante la costruzione del modello che ne mostrano la progressione.`,
        sectionTtp2: "Algoritmo di generazione procedurale in ambito videoludico",
        sectionProject3: `Progettazione di algoritmi per la generazione procedurale di contenuti videoludici.
                                    Implementazione effettuata in Unity 2D per il sistema automatizzato per la creazione di livelli e labirinti.
                                    </br></br>Nell'immagine si pu\u00F2 vedere una stanza creata interamente dall'algoritmo, viene gestito anche
                                    il piazzamento di nemici, oggetti e la creazione di un pattern del dungeon sempre 
                                    proceduralmente generato. In termini semplici partendo da una schermata vuota il 
                                    programma \u00E8 in grado di generare un vero e proprio labirinto.`,

        contactTitle: "Contatto",
        contactDescription: "Per qualsiasi cosa puoi scrivermi direttamente qui:",
        contactCTA: "Contattami",
    },
};

// Funzione per cambiare lingua
function changeLanguage(lang) {
    localStorage.setItem("lang", lang); // Salva la lingua scelta
    location.reload(); // Ricarica la pagina
}

// Attacca la funzione al contesto globale
window.changeLanguage = changeLanguage;

// Funzione per aggiornare i contenuti della pagina e gestire i pulsanti lingua
function updateContent(lang) {
    const texts = translations[lang];
    document.getElementById("hero-title").innerHTML = texts.heroTitle;
    document.getElementById("know-more").innerText = texts.knowMore;
    document.getElementById("about-title").innerText = texts.aboutTitle;
    document.getElementById("view-resume").innerText = texts.viewResume;
    document.getElementById("view-live").innerText = texts.viewLive;
    document.getElementById("view-live2").innerText = texts.viewLive;
    document.getElementById("view-live3").innerText = texts.viewLive;
    document.getElementById("about-description").innerText = texts.aboutDescription;
    document.getElementById("about-goal").innerText = texts.aboutGoal;
    document.getElementById("section-job").innerText = texts.sectionJob;
    document.getElementById("section-projects").innerText = texts.sectionProjects;

    // Descrizioni e titoli
    document.getElementById("section-job1").innerHTML = texts.sectionJob1;
    document.getElementById("section-job2").innerHTML = texts.sectionJob2;
    document.getElementById("section-pj1").innerHTML = texts.sectionProject1;
    document.getElementById("section-pjt").innerText = texts.sectionTtp;
    document.getElementById("section-pj2").innerHTML = texts.sectionProject2;
    document.getElementById("section-pjt2").innerText = texts.sectionTtp2;
    document.getElementById("section-pj3").innerHTML = texts.sectionProject3;

    document.getElementById("contact-title").innerText = texts.contactTitle;
    document.getElementById("contact-description").innerText = texts.contactDescription;
    document.getElementById("contact-cta").innerText = texts.contactCTA;

    // Gestisci la visibilit� dei pulsanti lingua
    document.getElementById("btn-en").style.display =
        lang === "en" ? "none" : "inline-block";
    document.getElementById("btn-it").style.display =
        lang === "it" ? "none" : "inline-block";
}

// Imposta la lingua all'avvio in base al localStorage
document.addEventListener("DOMContentLoaded", () => {
    const lang = localStorage.getItem("lang") || "en"; // Lingua di default
    updateContent(lang);

    // Aggiungi event listener ai pulsanti lingua
    document.getElementById("btn-en").addEventListener("click", () => {
        changeLanguage("en");
    });

    document.getElementById("btn-it").addEventListener("click", () => {
        changeLanguage("it");
    });
});