import initScrollReveal from "./scripts/scrollReveal";
import initTiltEffect from "./scripts/tiltAnimation";
import { targetElements, defaultProps } from "./data/scrollRevealConfig";

initScrollReveal(targetElements, defaultProps);
initTiltEffect();

// Definiamo le traduzioni
const translations = {
    en: {
        heroTitle: `Hi, I'm <span class="text-color-main">Maxim Daniel</span><br />software engineer and developer.`,
        knowMore: "Know more",
        aboutTitle: "About me",
        aboutDescription:
            "Description",
        contactTitle: "Contact",
        contactDescription: "For anything you can write to me directly here:",
        contactCTA: "Call to Action",
    },
    it: {
        heroTitle: `Ciao, sono <span class="text-color-main">Maxim Daniel</span><br />ingegnere del software e sviluppatore.`,
        knowMore: "Scopri di pi\u00F9",
        aboutTitle: "Su di me",
        aboutDescription:
            "Descrizione",
        contactTitle: "Contatto",
        contactDescription: "Per qualsiasi cosa puoi scrivermi direttamente qui:",
        contactCTA: "Chiamata all'Azione",
    },
};

// Funzione per cambiare lingua
function changeLanguage(lang) {
    localStorage.setItem("lang", lang); // Salva la lingua scelta
    location.reload(); // Ricarica la pagina
}

// Attacca la funzione al contesto globale
window.changeLanguage = changeLanguage;

// Funzione per aggiornare i contenuti della pagina e gestire i pulsanti lingua
function updateContent(lang) {
    const texts = translations[lang];
    document.getElementById("hero-title").innerHTML = texts.heroTitle;
    document.getElementById("know-more").innerText = texts.knowMore;
    document.getElementById("about-title").innerText = texts.aboutTitle;
    document.getElementById("about-description").innerText = texts.aboutDescription;
    document.getElementById("contact-title").innerText = texts.contactTitle;
    document.getElementById("contact-description").innerText = texts.contactDescription;
    document.getElementById("contact-cta").innerText = texts.contactCTA;

    // Gestisci la visibilit� dei pulsanti lingua
    document.getElementById("btn-en").style.display =
        lang === "en" ? "none" : "inline-block";
    document.getElementById("btn-it").style.display =
        lang === "it" ? "none" : "inline-block";
}

// Imposta la lingua all'avvio in base al localStorage
document.addEventListener("DOMContentLoaded", () => {
    const lang = localStorage.getItem("lang") || "en"; // Lingua di default
    updateContent(lang);

    // Aggiungi event listener ai pulsanti lingua
    document.getElementById("btn-en").addEventListener("click", () => {
        changeLanguage("en");
    });

    document.getElementById("btn-it").addEventListener("click", () => {
        changeLanguage("it");
    });
});